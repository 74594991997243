$(document).ready(function(){
	$('input[name=form_token]').attr('name', '_form_token');
	$('.datepicker-input').datepicker({
		dateFormat:'yyyy-mm-dd',
		position:'top right'
	}); 
	
});

// checkTVA

$('input.vatcheck, input.american-date').focus(function(){
	$(this).parents('label').removeClass("error-warning");
	$(this).parents('label').find('.error-message').hide();
});

$('input.vatcheck').blur(function(){
	
	var input = $(this);
	var vatnum = $(this).val().trim();
	
	if(vatnum.length > 0) {
		$.get("/sites/all/themes/scopeinvest/api/vat.php", {vat:vatnum, country:"be"}, function(response){
			if(!response.valid) {
				//alert('Your VAT number ' + response.vatNumber + ' is not valid for country ' + response.countryCode);
				input.parents('label').addClass("error-warning");
				$('input[name=_form_token]').val('');
			} else {
				$('input[name=_form_token]').val(Math.round(Math.random()*999999));
			}
		}, 'json');
	}
	
});

// check date

function isValidDate(dateString) {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!dateString.match(regEx)) return false;  // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0,10) === dateString;
}

$('input.american-date').blur(function(){
	
	var input = $(this);
	var date = $(this).val().trim();
		
	if(!isValidDate(date)) {
		input.parents('label').addClass("error-warning");
		input.parents('label').find('.error-message').show();
	}
	
});

$('form.tools').submit(function(){
	
	var error = false;
	
	$(this).find('input.vatcheck').each(function(){
		
		
	});
	
	return !error;
});

// SCROLL TO ID & #REMOVAL

$(function() {
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1500);
        return false;
      }
    }
  });
});

// HOVER MENU

$('header > ul > li').mouseenter(function() { 
  
  $('header ul li').removeClass('dropdown');
  $(this).addClass('dropdown');
  
});

$('header').mouseleave(function() {
  $('header ul li').removeClass('dropdown');
});

// BURGER MENU

$('#click').change(function() {
  if ($(this).is(':checked')) {
    $("body").addClass("open");
  } else {
    $("body").removeClass("open");
  }
  
  setTimeout(function() {
     sr.sync();
    }, 550);
});


// SCROLL

window.sr = ScrollReveal({ mobile: false, viewFactor: 0, reset: false });
sr.reveal('.content-container-block', {  });

// PARALLAX

$(document).ready(function(){
    var $win = $(window);
	
	$('header > ul > li > ul').each(function(){
		$(this).css({
		  'left': parseInt($(this).parent().css('padding-left')) + Math.round($(this).parent().width()/2),
		});
	});
	
	if('objectFit' in document.documentElement.style === false) {
		$('html').addClass('no-object-fit');
	}
	
	$('.separator.special').each(function(){
		var nextHeight = $(this).next().height();
		$('.separator').css('margin-top', Math.round(nextHeight/2) - Math.round($(this).height()/2) );
	});
	
    $('.separator-content, .landing-bg-img').each(function(){
        var scroll_speed = 5;
        var $this = $(this);
        $(window).scroll(function() {
            var bgScroll = -(($win.scrollTop() - $this.offset().top)/ scroll_speed);
            var bgPosition = 'center '+ bgScroll + 'px';
            $this.css({ backgroundPosition: bgPosition });
        });
    });
});

// SEARCH

var searchInMovies = function(){
		
	ScrollReveal().destroy();
	
	var filter = $('input[name="search"]').val();
	var formatId = parseInt($('select[name="format"]').val());
	var catId = parseInt($('select[name="category"]').val());
	
	var pillarsIds = [];
	$('input.pillar:checked').each(function(){
		pillarsIds.push(parseInt($(this).val()));
	});
		
	if(filter.trim().length <= 0 && !catId && !formatId && pillarsIds.length == 0 ) {
		$('.grid .content-container-block').show();
		return;
	}
		
	var moviesIds = [];
	$('.grid .content-container-block').each(function(){
		moviesIds.push($(this).attr('id'));
	}); 
	
	if(moviesIds.length > 0) {	
		
		var mid, movieBlock;
			
		if(catId > 0) {
			for(var o in moviesIds) {
				movieBlock = $('#' + moviesIds[o]);
				var categories = movieBlock.data('category');
				if(!categories || categories.indexOf(catId) == -1) {
					delete moviesIds[o];
				}
			}
		}
		
		if(formatId > 0) {
			for(var o in moviesIds) {
				movieBlock = $('#' + moviesIds[o]);
				var formats = movieBlock.data('format');
				if(!formats || formats.indexOf(formatId) == -1) {
					delete moviesIds[o];
				}
			}
		}
		
		if( pillarsIds.length > 0 ) {
			for(var o in moviesIds) {
				movieBlock = $('#' + moviesIds[o]);
				var pillars = movieBlock.data('pillars');
				
				if(pillars !== undefined) {
					
					if(pillars.length == 0){
						delete moviesIds[o];
					} else {
						
						var checks = [];
						
						for(var pilID in pillars){
							var pillar = pillars[pilID];
							
							for(var p in pillarsIds){
								var pid = pillarsIds[p];
								if( parseInt(pid) == parseInt(pillar) ){
									checks.push(pid);
								}
							}
						}
						
						if(checks.length < pillarsIds.length) {
							 delete moviesIds[o];
						}
					}					
				}
			}
		}
		
		if( filter.trim().length > 0 ) {
			
			for(var u in moviesIds) {
				
				if(undefined === moviesIds[u]) continue;
				
				movieBlock = $('#' + moviesIds[u]);
				var actors = movieBlock.data('actors');
				var title = movieBlock.find('h3').text().toLowerCase();
				
				if( typeof actors == "string" ) {
					try {
						actors = JSON.parse(actors);
					} catch(e){}
					if( typeof actors == "string" ) {
						actors = actors.split(',');
					}
				}
					
				var valids = actors.filter(function(item){
					return item.toLowerCase().indexOf(filter.toLowerCase().trim()) >= 0;
				});
				
				if(undefined != title && title.indexOf(filter.toLowerCase().trim()) > -1 ) {
					valids.push(title);
				}
					
				if(valids.length == 0) {
					mid = movieBlock.attr('id');
					delete moviesIds[u];
				}
				
			}
		}
		
		$('.grid .content-container-block').hide();
		for(var i in moviesIds){
			if(moviesIds[i]) {
				var mid = moviesIds[i];
				if(mid) {
					var m = $('#' + mid);
					//console.log(pillarsIds, m.data('pillars'));
					m.show().css('opacity', 1);
				}
			}
		} 
	}	
};

$(document).ready(function(){
	
	if( $('.content-container-search').length > 0 ) {
		
		$('input[name="search"]').keyup(searchInMovies);		
		$('select[name="format"]').change(searchInMovies);
		$('select[name="category"]').change(searchInMovies);
		$('input.pillar').change(function(){
			searchInMovies();
		});		
	}	
});

// Modals

$(document).ready(function(){
	
	$('.modal .close').click(function(){
		$('.modal').fadeOut(function(){
			$(this).remove();
		});
	});
});
 
//CTA NEWSLETTER

$('.modal_opener').click(function(){
	$(this).parents('.stored').toggleClass('moved');
	return false;
});
// BURGER MENU
$('.burger_menu').click(function(){
    $('.menu_list').toggleClass('moved_menu');
    $('header').toggleClass('burger_actif');
});


$('.menu_list >li >a').click(function(){
	$(this).next('ul').slideToggle(400).toggleClass('opened');
	$(this).toggleClass('menu_link-actif');
	
});
$('.submenu').click(function(){
    $(this).toggleClass('submenu_actif');
});


// MAP GOOGLE

      function myMap() {
		  // 50.84059513575414, 4.361174085697406
        //var myLatLng = {lat: 50.827778, lng: 4.359558};
		var myLatLng = {lat: 50.839155, lng: 4.365702};

        var map = new google.maps.Map(document.getElementById('map-canvas'), {
          zoom: 18,
          center: myLatLng
        });

        var marker = new google.maps.Marker({
          position: myLatLng,
          map: map,
          title: 'Hello World!'
        });
      }


 $('.slider').slick({
    draggable: true,
    arrows: false,
    dots: true,
    fade: true,
    speed: 900,
    autoplay: true,
	autoplaySpeed: 4000,
    infinite: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    touchThreshold: 100
  });

  $($('.item_text').get(0)).removeClass('hidden');
  
  $('.slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
  		$('.item_text').addClass('hidden');
  		$($('.item_text').get(nextSlide)).removeClass('hidden');
	});
	
	
(function($, undefined) {


	$('input.formatNumber').each(function(){
		new Cleave(this, {
		    numeral: true,
		    numeralDecimalMark: ',',
		    delimiter: '.'
		});
	});
	

})(jQuery);



// LANGUAGE

// $('.language_choice').click(function(){
//     $(this).addClass('lang_active');
// });



      function setActive() {
        aObj = $('.language_choice');
        for(i=0;i<aObj.length;i++) {
          if(document.location.href.indexOf(aObj[i].href)>=0) {
            aObj[i].className='.active_lang';
          }
        }
      }
      window.onload = setActive;



// GDPR MODAL - COOKIE BAR 

$('.btn-choice ').click(function() {
  	$(this).parents('.category').toggleClass('active');
});


$('.cookie_infos').click(function() {
  	$(".modal-gdpr").addClass("show");
});

$('.close').click(function() {
  	$(".modal-gdpr").removeClass("show");
});


$('.open-cookie').click(function() {
  	$(".cookie-bar").removeClass("noshow");
  	return false;
});

var gdprGetParams = {};
$('.accept_cookie').click(function() {
  	$(".cookie-bar").addClass("noshow");
  	
	setCookie('_gdpr-settings', JSON.stringify(gdprGetParams) );

  	$.get( THEME_PATH + "/partials/analytics.php" , gdprGetParams, function(scripts){
  		$("body").append(scripts)
  	} ,"html"  );
  	
  	return false;
});

var setCookie = function(name, value) {
    var d = new Date;
    d.setTime(d.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

$('.btn-save').click(function(){
		
		var categories = $('.category.active');
		categories.each(function(){
			var track = $(this).data('tracking');
			gdprGetParams[track] = 1;
		});
		
		$(".modal-gdpr").removeClass("show");

		return false;
}); 

var currentUrl = window.location.pathname;

	$('[href="'+currentUrl+'"],[href="'+currentUrl+'/"]').addClass('active');

	$('.level-3 [href="'+currentUrl+'"], .level-3 [href="'+currentUrl+'/"]').parents('.level-2').prev().addClass('parent_active');


$(document).ready(function(){
	var testmenu = $( ".menu_list .active" );

	if (testmenu) {
		testmenu.parents(".submenu-title").addClass('menu_actif');
	}
});

// Covid-19

// $(document).ready(function(){
	
// 	var lang = $('html').attr('lang').trim();
	
// 	if( lang == 'en' || lang == 'de' ) {
// 		$('.cta-block-covid').hide();
// 		return false;
// 	}
	
// 	$.get( '/' + THEME_PATH + '/partials/modal_covid.php?lg=' + lang, function(html) {
		
// 		$('body').prepend(html);
		
// 		$('.close_modal-covid').click(function(){
// 		    $('.msg_covid').fadeOut(300);
// 		});
// 		$('.cta-block-covid').click(function(){
// 		    $('.msg_covid').fadeIn(300);
// 		});
		
// 		if( document.cookie.indexOf('_covid_modal_' + lang) < 0 ) {
			
// 			$('.msg_covid').fadeIn(300);
// 			setCookie('_covid_modal_' + lang, 1 );
// 		}
		
// 	}, 'html');

// });